import { ReactElement, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Text,
  Tooltip,
  useDisclosure,
  useMediaQuery,
  useTheme,
  InputRightElement
} from '@chakra-ui/react';
import { useRouter } from 'next/router';
import NextLink from 'next/link';
import { Controller, useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useStore } from 'store';
import ApiError from '../common/APIError';
import { FORGOT_PASSWORD, FORGOT_USERNAME, PRESCRIPTION_PROFILE_URL } from 'utils/constants';
import useModalPageViewEventHandler from 'hooks/useModalPageViewEventHandler';
import UpdatePasswordModal from '../UpdatePasswordModal';
import { GTMClickEvent } from 'utils/gtmHelpers';

const LoginForm = (): ReactElement => {
  const [isSigningIn, setIsSigningIn] = useState<boolean>(false);
  const [apiError, setApiError] = useState<string>('');
  const [savedCards, setSavedCards] = useState<Array<{ id: string; name: string }>>([]);
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const savedProfileDisclosure = useDisclosure();
  const updatePasswordDisclosure = useDisclosure();
  const whatsThisDisclosure = useDisclosure();
  const { handleSubmit, control, setValue, clearErrors } = useForm();
  const { modalsAndDrawersStore } = useStore();
  const router = useRouter();
  const theme = useTheme();
  const { patientStore } = useStore();
  const [isMobile] = useMediaQuery('(max-width:767px )');
  useModalPageViewEventHandler({
    modalName: 'SavedFamilyProfiles',
    modalIsOpen: savedProfileDisclosure?.isOpen
  });

  useEffect(() => {
    const savedCardsRaw = localStorage.getItem('SAVED_CARDS');
    setSavedCards(typeof savedCardsRaw === 'string' ? JSON.parse(savedCardsRaw) : []);
  }, []);

  const onSubmit = async (values: {
    cardNumber: string;
    password: string;
    rememberCard: boolean;
  }) => {
    setIsSigningIn(true);
    try {
      const loginResponse = await patientStore.login(values.cardNumber, values.password);

      if (loginResponse?.passwordUpdateRequired) {
        modalsAndDrawersStore.updatePasswordData({
          id: values?.cardNumber,
          pin: values?.password
        });
        updatePasswordDisclosure.onOpen();
        setIsSigningIn(false);
        return;
      }

      if (loginResponse?.hasErrors) {
        setIsSigningIn(false);
        setApiError(loginResponse?.errors || 'Something went wrong. Please try again.');
        return;
      }
      if (values.rememberCard) {
        const rawExistingCards = localStorage?.getItem('SAVED_CARDS');
        const existingCards =
          typeof rawExistingCards === 'string' ? JSON.parse(rawExistingCards) : [];

        if (
          existingCards.filter(
            (card: { id: string; name: string }) => card.id === values.cardNumber
          ).length === 0
        ) {
          localStorage?.setItem(
            'SAVED_CARDS',
            JSON.stringify([
              ...existingCards,
              {
                name: `${loginResponse?.firstName} ${loginResponse?.lastName}`,
                id: values.cardNumber
              }
            ])
          );
        }
      }

      setIsSigningIn(false);
      router.push(PRESCRIPTION_PROFILE_URL);
    } catch (error) {
      console.error('ERROR SIGNING IN');
      console.error(error);
      setApiError('Something went wrong. Please try again.');
      setIsSigningIn(false);
    }
  };

  return (
    <>
      <UpdatePasswordModal
        onClose={updatePasswordDisclosure?.onClose}
        isOpen={updatePasswordDisclosure?.isOpen}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        {apiError && <ApiError message={apiError} />}
        <Box>
          <Flex alignItems="center" justifyContent="space-between">
            <FormLabel htmlFor="cardNumber" fontSize="md">
              User ID
            </FormLabel>
            {isMobile ? (
              <Text
                marginBottom="2"
                textDecoration="underline"
                onClick={whatsThisDisclosure.onOpen}
              >
                What's this?
              </Text>
            ) : (
              <Tooltip
                padding="3"
                label="Your User ID is the number issued to you by the pharmacy staff.  If you need to obtain a User ID please contact your local London Drugs Pharmacy."
              >
                <Text marginBottom="2" textDecoration="underline">
                  What's this?
                </Text>
              </Tooltip>
            )}
          </Flex>
          <Controller
            name="cardNumber"
            control={control}
            rules={{ required: 'This field is required' }}
            defaultValue=""
            render={({
              field: { onChange, onBlur, value, name },
              fieldState: { error, invalid }
            }) => (
              <FormControl isInvalid={!!invalid}>
                <InputGroup position="relative">
                  <Input
                    placeholder="Enter User ID"
                    onChange={onChange}
                    onBlur={onBlur}
                    type="number"
                    value={value}
                    name={name}
                    id={name}
                  />
                  {savedCards?.length > 0 && (
                    <Button variant="outline" position="absolute" right="0" border="0">
                      <Text
                        color="primary.default"
                        fontWeight="normal"
                        textDecoration="underline"
                        fontSize="smaller"
                        onClick={savedProfileDisclosure?.onOpen}
                      >
                        Saved User IDs
                      </Text>
                    </Button>
                  )}
                </InputGroup>
                <FormErrorMessage>{error?.message}</FormErrorMessage>
              </FormControl>
            )}
          />
        </Box>
        {/* <NextLink href={FORGOT_USERNAME} passHref>
            <a
              onClick={() => {
                GTMClickEvent('Header - Forgot Username', 'global');
              }}
            >
              <Text color="shades.300" fontSize={{base:"xs", md:"sm"}} lineHeight={{base:"20px"}} marginTop="5px">
                Forgot UserId?
              </Text>
            </a>
          </NextLink> */}
        <Box marginTop="4">
          <FormLabel htmlFor="password" fontSize="md">
            Password
          </FormLabel>
          <Controller
            name="password"
            control={control}
            rules={{ required: 'This field is required' }}
            defaultValue=""
            render={({
              field: { onChange, onBlur, value, name },
              fieldState: { error, invalid }
            }) => (
              <FormControl isInvalid={!!invalid}>
                <Input
                  placeholder="********"
                  type={showPassword ? 'text' : 'password'}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  name={name}
                  id={name}
                />
                <InputRightElement>
                  <Text
                    height="40px"
                    border="0px"
                    paddingY="2"
                    paddingX="2"
                    variant="ghost"
                    color="shades.300"
                    zIndex="200"
                    // right="3"
                    _hover={{ bgColor: 'transparent', color: 'shades.400', cursor: 'pointer' }}
                    onClick={() => setShowPassword((showPassword) => !showPassword)}
                  >
                    {showPassword ? 'Hide' : 'Show'}
                  </Text>
                </InputRightElement>
                <FormErrorMessage>{error?.message}</FormErrorMessage>
              </FormControl>
            )}
          />
        </Box>
        {/* <NextLink href={FORGOT_PASSWORD} passHref>
          <a
            onClick={() => {
              GTMClickEvent('Header - Forgot Password', 'global');
            }}
          >
            <Text color="shades.300" fontSize={{ base: "xs", md: "sm" }} lineHeight={{ base: "20px" }} marginTop="5px">
              Forgot Password?
            </Text>
          </a>
        </NextLink> */}
        <Box marginTop="4">
          <Controller
            name="rememberCard"
            control={control}
            defaultValue={false}
            render={({ field: { onChange, onBlur, value, name } }) => (
              <Checkbox onChange={onChange} onBlur={onBlur} checked={value} name={name} id={name}>
                Remember this user ID
              </Checkbox>
            )}
          />
        </Box>
        <Box marginTop="4" width="50%">
          <Button variant="ld" width="100%" fontSize="large" type="submit" isLoading={isSigningIn}>
            Sign In
          </Button>
        </Box>
        <Box marginTop={{ base: '6', md: '4' }}>
          <Text fontSize={{ base: 'sm', lg: 'md' }}>
            <Text as="span" fontWeight="bold">
              Trouble logging in?
            </Text>{' '}
            Please{' '}
            <NextLink href="https://www.londondrugs.com/locations" passHref>
              <Text as="a" target="_blank" rel="noopener" textDecoration="underline">
                contact the pharmacy
              </Text>
            </NextLink>{' '}
            to obtain your user ID / password
          </Text>
        </Box>
        <Modal
          isCentered
          onClose={savedProfileDisclosure?.onClose}
          isOpen={savedProfileDisclosure?.isOpen}
          motionPreset="slideInBottom"
        >
          <ModalOverlay />
          <ModalContent marginX={{ base: '3', md: '0' }}>
            <ModalHeader backgroundColor="primary.default" color="white" fontSize="md">
              Saved Family Profiles
            </ModalHeader>
            <ModalCloseButton color="white" />
            <ModalBody padding="0" marginBottom="5">
              {savedCards?.map(({ name, id }, index) => (
                <Box key={id}>
                  <Flex alignItems="center" paddingX="5" paddingY="2">
                    <Flex
                      flex={1}
                      justifyContent="space-between"
                      onClick={() => {
                        setValue('cardNumber', id);
                        clearErrors('cardNumber');
                        savedProfileDisclosure?.onClose();
                      }}
                      cursor="pointer"
                    >
                      <Text fontWeight="semibold" fontSize="large">
                        {name}
                      </Text>
                      <Text
                        color="shades.200"
                        fontWeight="semibold"
                        fontSize="large"
                        marginRight="6"
                      >
                        {id}
                      </Text>
                    </Flex>
                    <Box
                      as="button"
                      onClick={async () => {
                        const newSavedCards = savedCards?.filter(
                          (card, _index) => index !== _index
                        );
                        await localStorage?.setItem('SAVED_CARDS', JSON.stringify(newSavedCards));

                        setSavedCards(newSavedCards);

                        if (newSavedCards?.length === 0) {
                          savedProfileDisclosure?.onClose();
                        }
                      }}
                    >
                      <FontAwesomeIcon
                        icon={['fas', 'times-circle']}
                        color={theme.colors.shades['200']}
                        style={{ width: 30, height: 30 }}
                      />
                    </Box>
                  </Flex>
                  <Divider />
                </Box>
              ))}
            </ModalBody>
          </ModalContent>
        </Modal>
        <Modal isCentered isOpen={whatsThisDisclosure.isOpen} onClose={whatsThisDisclosure.onClose}>
          <ModalOverlay />
          <ModalContent marginX="3">
            <ModalCloseButton />
            <ModalBody paddingX="6" paddingTop="10" paddingBottom="6">
              <Text>
                Your User ID is the number issued to you by the pharmacy staff. If you need to
                obtain a User ID please contact your local London Drugs Pharmacy.
              </Text>
            </ModalBody>
          </ModalContent>
        </Modal>
      </form>
    </>
  );
};

export default LoginForm;
