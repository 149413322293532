import { Box, Heading, Stack, Text } from '@chakra-ui/react';
import { ReactElement } from 'react';
import { useRouter } from 'next/router';

import { UnknownObjectAny } from 'global.types';
import {
  Banner,
  BlockQuote,
  FeatureBanner,
  FeatureSplit,
  ImageBanner3Up,
  ImageBannerFullWidth,
  ListGenerator,
  PageBodyText,
  PageSummaryBlock,
  SideBySideBanners
} from '.';
import { CONTENT_TYPES } from 'utils/constants';
import { BannerCMSData, ImageBannerData, PageSummaryBlockData } from 'types/cms';
import PageContentContainer from 'components/containers/PageContentContainer';
import { presetComponentProps } from 'theme';
import { getFormattedEmbedVideo } from 'utils/contentful-utils';

const headlineRegex =
  /^(How to get started on your caregiver support journey|Locations|(Asymptomatic COVID-19 Testing Clinic Locations).+)$/i;
const pathRegex =
  /^(\/health-clinics\/fertility-health|\/pharmacy-services\/teva-caregiver-support-program|\/covid-19-information\/alberta|\/covid-19-information\/british-columbia)(#book|$)$/;

export interface ComponentGeneratorProps extends UnknownObjectAny {
  __typename: string;
}

const ComponentGenerator = ({
  __typename,
  ...props
}: ComponentGeneratorProps): ReactElement | null => {
  const { asPath } = useRouter();
  // https://64labs.atlassian.net/browse/LDP-137 & https://64labs.atlassian.net/browse/LDP-143: Need to insert an id for deep linking to the Locations section of various pages
  const shouldHaveBookHash =
    props?.headline && headlineRegex.test(props.headline) && pathRegex.test(asPath);
  switch (__typename) {
    case CONTENT_TYPES.SIDE_BY_SIDE_BANNERS:
      return (
        <PageContentContainer>
          <SideBySideBanners
            {...props}
            bannersCollection={props?.bannersCollection}
            title={props?.title}
          />
        </PageContentContainer>
      );
    case CONTENT_TYPES.LIST:
      return (
        <PageContentContainer>
          {(props?.headline || props?.text) && (
            <Stack
              alignItems="center"
              mb={{ base: '1rem', md: '2rem' }}
              spacing={{ base: '1', md: '3' }}
            >
              {props?.headline && (
                <Heading
                  {...presetComponentProps?.h2}
                  color={props?.headlineColor || 'primary.default'}
                  id={shouldHaveBookHash ? 'book' : ''}
                >
                  {props?.headline}
                </Heading>
              )}
              {props?.text && <Text>{props.text}</Text>}
            </Stack>
          )}
          <ListGenerator {...props} itemCollection={props.itemCollection} />
        </PageContentContainer>
      );
    case CONTENT_TYPES.FEATURE_SPLIT:
      return (
        <PageContentContainer isFullWidth>
          <FeatureSplit
            {...props}
            title={props.title}
            image={props.image}
            imageLocation={props.imageLocation}
          />
        </PageContentContainer>
      );
    case CONTENT_TYPES.BLOCK_QUOTE:
      return (
        <PageContentContainer>
          <BlockQuote {...props}>{props.blockQuoteText}</BlockQuote>
        </PageContentContainer>
      );
    case CONTENT_TYPES.FEATURE_BANNER:
      return (
        <PageContentContainer isFullWidth>
          <FeatureBanner bannerText={props.bannerText} {...props} />
        </PageContentContainer>
      );
    case CONTENT_TYPES.PAGE_BODY_TEXT:
      return (
        <PageContentContainer>
          <PageBodyText
            data={{ title: props?.title, pageBodyText: props?.pageBodyText, __typename }}
          />
        </PageContentContainer>
      );
    case CONTENT_TYPES.BANNER_IN_PAGE:
      return (
        <PageContentContainer isFullWidth={props?.width?.toLocaleLowerCase() === 'full-width'}>
          <Banner {...{ __typename, ...(props as BannerCMSData) }} inPageBanner />
        </PageContentContainer>
      );
    case CONTENT_TYPES.IMAGE_FULL_WIDTH_BANNER:
      return (
        <PageContentContainer isFullWidth>
          <ImageBannerFullWidth {...({ __typename, ...props } as ImageBannerData)} />
        </PageContentContainer>
      );
    case CONTENT_TYPES.IMAGE_BANNER_THREE_UP:
      return (
        <PageContentContainer isFullWidth>
          <ImageBanner3Up {...({ __typename, ...props } as ImageBannerData)} />
        </PageContentContainer>
      );
    case CONTENT_TYPES.PAGE_SUMMERY_BLOCK:
      return (
        <PageContentContainer>
          <PageSummaryBlock {...(props as PageSummaryBlockData)} />
        </PageContentContainer>
      );
    case CONTENT_TYPES.IFRAME_VIDEO:
      if (getFormattedEmbedVideo(props)) {
        return (
          <Box
            w="100%"
            mb={{ base: '10', md: '16' }}
            maxW="100vw"
            display="flex"
            justifyContent="center"
          >
            {getFormattedEmbedVideo(props)}
          </Box>
        );
      }
      return <></>
    default:
      return null;
  }
};

export default ComponentGenerator;
