export const fetchAuthToken = async () => {
    try {
        const url = `${process.env.KIBO_URL}/platform/applications/authtickets/oauth`;
        const site_id = process.env.KIBO_SITE_ID ?? "";
        const body = {
            "client_id": process.env.KIBO_CLIENT_ID,
            "client_secret": process.env.KIBO_SECRET_ID
        }
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "x-vol-site": site_id,
            },
            body: JSON.stringify(body)
        });
        const resJson = await response?.json();
        return resJson?.access_token ?? ""
    } catch (error) {
        console.log(`Error in fetching auth token: ${error}`)
        return ''
    }
}