import TagManager from 'react-gtm-module';
import { capitalizeAllWordsInString } from 'utils';

export interface GTMPageViewEventData {
  page_url: string;
  page_title: string;
  currentPrescriptions?: string | null;
}

export const GTMPageViewEvent = (pageViewArgs: GTMPageViewEventData): null => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'page_view',
      ...pageViewArgs
    }
  });
  return null;
};

export const GTMGeneralEvent = (pageViewArgs: { event: string; [key: string]: any }): null => {
  TagManager.dataLayer({
    dataLayer: {
      ...pageViewArgs
    }
  });
  return null;
};

export const GTMClickEvent = (clickText: string | undefined, asPath?: string): null => {
  const nestedAsPath =
    asPath === undefined ? undefined : asPath?.split('/')?.[asPath.split('/')?.length - 1];
  const pageName = !nestedAsPath
    ? ''
    : capitalizeAllWordsInString(nestedAsPath.replace(/\//g, '').replace(/-/g, ' '));

  GTMGeneralEvent({
    event: 'click_button',
    click_details: `${clickText ?? 'Text was not provided by CMS for this click event'}${
      asPath === 'global' || asPath === undefined ? '' : ' - ' + pageName
    }`
  });

  return null;
};
