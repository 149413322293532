import { createContext, useContext } from 'react';
import { RootStore } from './models/root.model';

const context = createContext({});

export const StoreProvider = context.Provider;

export const useStore = (): { [key: string]: any } => {
  return useContext(context);
};

export const createStore = (initial?: { [key: string]: any }) => {
  return RootStore.create(initial);
};
